<template>
  <div>
    <div
      v-for="(question, index) in questions"
      :key="index + question[0]"
      class="questionContainer"
    >
      <inputOptional
        :value="question[0]"
        :label="$t('survey.question', { count: index + 1 })"
        :callback="function(ret) { setValue(index, ret) }"
      />

      <a
        href="#"
        class="flix-btn"
        v-if="questions.length > 1"
        @click.prevent="removeQuestion(index)"
      >
        <icon id="bin" />
      </a>
      &nbsp;
      <a href="#" class="flix-btn" @click.prevent="addQuestion(index)">
        <icon id="plus" />
      </a>
      &nbsp;
      <a
        href="#"
        class="flix-btn flix-btn-default"
        v-if="index != questions.length - 1"
        @click.prevent="changeQuestion(index, 1)"
      >
        <icon id="chevron-down" />
      </a>
      &nbsp;
      <a
        href="#"
        class="flix-btn flix-btn-default"
        v-if="index != 0"
        @click.prevent="changeQuestion(index, -1)"
      >
        <icon id="chevron-up" />
      </a>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      questions: this.getQuestions()
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getQuestions() {
      if (this.$store.state.business.unsaved.survey.questions) {
        return this.$store.state.business.unsaved.survey.questions;
      } else {
        this.questions = [[this.$t("data.question")]];
        this.setSave();
        return this.questions;
      }
    },
    setSave() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      data.survey.questions = this.questions;
      this.$store.commit("business/prefetch", data);
    },
    changeQuestion(index, position) {
      this.questions.splice(
        index + position,
        0,
        this.questions.splice(index, 1)[0]
      );
    },
    removeQuestion(index) {
      this.questions.splice(index, 1);
    },
    addQuestion(index) {
      this.questions.splice(index * 1 + 1, 0, [""]);
      this.questions[index * 1 + 1] = [""];
    },
    setValue(index, data) {
      this.questions[index][0] = data;
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables.sass'
.questionContainer
  margin-bottom: 30px
  display: block
</style>
